import { Injectable } from '@angular/core';

import { ApiConfig } from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { ApiModule } from './api.module';

@Injectable({
  providedIn: ApiModule
})
export class SharedApiService {
  constructor(
    private authService: AuthService,
    private config: ApiConfig
  ) {}

  async get(url = '', reqHeaders = true) {
    const response = await fetch(this.config.baseUrl + url, {
      method: 'GET',
      headers: this.getHeaders(reqHeaders)
    });
    if (response.status === 204) {
      return null;
    }
    return await response.json();
  }

  async delete(url = '') {
    const response = await fetch(this.config.baseUrl + url, {
      method: 'DELETE',
      headers: this.getHeaders()
    });

    return response;
  }

  async patch(url = '', data = {}) {
    const response = await fetch(this.config.baseUrl + url, {
      method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
      headers: this.getHeaders(),
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    if (response.status === 204) {
      return null;
    }
    return response.json(); // parses JSON response into native JavaScript objects
  }

  async patchNoReturn(url = '', data = {}) {
    const response = await fetch(this.config.baseUrl + url, {
      method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
      headers: this.getHeaders(),
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    return response.status; // parses JSON response into native JavaScript objects
  }

  async put(url = '', data = {}) {
    const response = await fetch(this.config.baseUrl + url, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: this.getHeaders(),
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    if (response.status === 204) {
      return null;
    }
    return response.json(); // parses JSON response into native JavaScript objects
  }

  async putNoReturn(url = '', data = {}) {
    const response = await fetch(this.config.baseUrl + url, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      headers: this.getHeaders(),
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.status; // parses JSON response into native JavaScript objects
  }

  async post(url = '', data = {}) {
    const response = await fetch(this.config.baseUrl + url, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(data)
    });

    return response.json();
  }

  async postNoReturn(url = '', data = {}) {
    const response = await fetch(this.config.baseUrl + url, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(data)
    });

    return response.status;
  }

  getHeaders(reqAuth = true) {
    const headers: HeadersInit = {
      'Content-Type': 'application/json'
    };
    if (reqAuth) headers['Authorization'] = `Bearer ${this.authService.getAccessToken()}`;
    return headers;
  }

  async deleteNoReturn(url = '', data = {}) {
    const response = await fetch(this.config.baseUrl + url, {
      method: 'DELETE',
      headers: this.getHeaders(),
      body: JSON.stringify(data)
    });

    return response.status;
  }
}
