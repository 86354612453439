import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMapTo, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

/**
 * `CanActivate` route guard that will wait until an access token
 * has been acquired and then further wait on the results of
 * `AuthService.isAuthorizedAppUser$`.
 *
 * Where the user is deemed unauthorized the SPA will be routed
 * to the client-side route configured using `AuthConfig.unauthorizedRoute`
 */
@Injectable({
  providedIn: 'root'
})
export class AuthzAppUserGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.currentUser$.pipe(
      filter(user => user.hasValidAccessToken),
      switchMapTo(this.authService.isAuthorizedAppUser$),
      map(
        isAuthorized =>
          isAuthorized || this.router.parseUrl(this.authService.config.unauthorizedRoute || 'unauthorized')
      ),
      take(1)
    );
  }
}
