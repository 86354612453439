import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AppUserAttributes } from '../models/app-user';
import { ClientService } from '../services/client.service';
import { ApiModule } from './api.module';
import { SharedApiService } from './shared-api.service';

@Injectable({
  providedIn: ApiModule
})
export class HelloApiService {
  constructor(
    private sharedApiService: SharedApiService,
    private clientService: ClientService
  ) {}

  getHello(): Observable<AppUserAttributes> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/hello`;
    return from(this.sharedApiService.get(url, true));
  }
}
