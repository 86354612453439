import { ApplicationUser } from '../application-user';

const baseExample: ApplicationUser = {
  applicationPkId: 1,
  userId: '1',
  disabled: false
};

export const createExampleApplicationUsers = (list: Partial<ApplicationUser>[]): ApplicationUser[] =>
  list.map(data => ({
    ...baseExample,
    ...data
  }));
