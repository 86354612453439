import { KeyValueDiffers } from '@angular/core';
import { toDictionary } from '@rx-angular/cdk/transformations';
import { subtractObject } from './subtract-object';

// note: subtractObjects is NOT in the lodash library but it should be!
export const subtractObjects = <T extends object>(
  minuend: T[],
  subtrahend: T[],
  key: keyof T,
  differs: KeyValueDiffers
) => {
  if (minuend.length === 0) {
    return {};
  }

  const minuendMap = toDictionary(minuend, key as never);
  const subtrahendMap = toDictionary(subtrahend, key as never);
  const differFactory = differs.find(minuend[0]);

  const initialDiffs: { [key: string]: Partial<T> } = {};
  const diffs = Object.entries(minuendMap).reduce((results, [keyValue, value]) => {
    const diff = subtractObject(value, subtrahendMap[keyValue], differFactory);
    return Object.keys(diff).length
      ? Object.assign(results, {
          [keyValue]: diff
        })
      : results;
  }, initialDiffs);

  return diffs;
};
