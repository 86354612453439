import { ApplicationUser } from './application-user';

export interface ApplicationVisibility extends ApplicationUser {
  show: boolean;
}

export const isApplicationVisible = (item: ApplicationVisibility) => item.show;

export const toApplicationUser = <T extends ApplicationVisibility>({ userId, applicationPkId }: T) => ({
  userId,
  applicationPkId
});

export function toggleApplicationVisibility<T extends ApplicationVisibility>(toggled: T[], existing: T[]): T[] {
  // todo: validate that `keys` exists in `existing` array
  return toggled.map(t => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const current = existing.find(e => e === t)!;
    return { ...current, show: !current.show };
  });
}
