import { User } from '../user';

const baseExample: User = {
  id: '1',
  givenName: 'Sample',
  familyName: 'Sample',
  email: 'sample@mycomp.com',
  isAppGatewayEnabled: false,
  isAdmin: false
};

export const createExampleUsers = (list: Partial<User>[]): User[] =>
  list.map((data, i) => ({
    ...baseExample,
    ...data,
    ...{ email: generateEmail(data, i) },
    id: i.toString()
  }));

function generateEmail(data: Partial<User>, i: number) {
  if (data.givenName && data.familyName) {
    return data.email ?? `${data.givenName}.${data.familyName}@mycomp.com`;
  } else {
    return data.email ?? `${baseExample.familyName}-${i}@mycomp.com`;
  }
}
