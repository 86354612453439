import { Dictionary } from 'lodash';
import { AdminApplication } from './admin-application';
import { ApplicationUser, isApplicationVisibleToUser } from './application-user';
import { ApplicationVisibility } from './application-visibility';
import { User } from './user';

export interface UserApplicationDetail extends User {
  applications: ApplicationDetail[];
  setUserDetail(user: User): void;
  getUserDetail(): User;
  getApplicationList(): ApplicationDetail[];
  updateApplications(updates: ApplicationUser[]): void;
}

export interface ApplicationDetail extends AdminApplication, ApplicationVisibility {}

export function getApplicationUser(app: ApplicationDetail): ApplicationUser {
  return {
    userId: app.userId,
    applicationPkId: app.applicationPkId,
    disabled: !app.show
  };
}

export function createUserApplicationDetail(
  user: User,
  applications: AdminApplication[],
  applicationUsers: Dictionary<ApplicationUser>
): UserApplicationDetail {
  return {
    ...user,
    applications: applications.map(app => createApplicationDetail(app, user.id, applicationUsers)),
    setUserDetail(user) {
      Object.assign(this, user);
    },
    getUserDetail() {
      return user;
    },
    getApplicationList() {
      return this.applications;
    },
    updateApplications(updates) {
      updates.forEach(update => {
        const index = this.applications.findIndex(app => app.applicationPkId === update.applicationPkId);
        this.applications[index].show = !update.disabled;
      });
    }
  };
}

function createApplicationDetail(
  app: AdminApplication,
  userId: string,
  applicationUsers: Dictionary<ApplicationUser> = {}
): ApplicationDetail {
  const show = isApplicationVisibleToUser(applicationUsers)(app);

  return {
    ...app,
    userId,
    disabled: !show,
    show
  };
}
