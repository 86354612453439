import { CdnFile } from './cdn-file';

export interface AvatarIconFile {
  iconFileName: string;
  iconSource: string;
  iconPath: string;
}

export interface AvatarIcon extends Partial<AvatarIconFile> {
  iconBackgroundColor: string;
  iconInitials?: string;
}

export const avatarDefaultCaptionBgColor = '#778692';
export const avatarDefaultImageBgColor = '#F1F4F6';

export const avatarIconFileFromCdnFile = ({ fileName, locationUrl, path }: CdnFile): AvatarIconFile => ({
  iconFileName: fileName,
  iconSource: locationUrl,
  iconPath: path
});

export const toAvatarIcon = <T extends AvatarIcon>(value: T): AvatarIcon => {
  const { iconBackgroundColor, iconFileName, iconInitials, iconPath, iconSource } = value;
  return {
    iconBackgroundColor,
    iconFileName,
    iconInitials,
    iconPath,
    iconSource
  };
};
