import { createModelMetadata, findBySearch } from '@mri-platform/shared/core';
import { ApplicationUser } from './application-user';

export interface User {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  isAppGatewayEnabled: boolean;
  isAdmin: boolean;
}

export interface UserPayload {
  changes: UserChanges[];
  tag: string;
}

export interface UserApplicationPayload {
  changes: UserApplicationChanges[];
  tag: string;
}

export interface UserChanges {
  entityName: string;
  entities: User[];
  op: string;
}

export interface DefaultClientIdPayLoad {
  defaultClientId: string | null;
}

export interface UserApplicationChanges {
  entityName: string;
  entities: ApplicationUser[];
  op: string;
}

export const userFullname = ({ givenName, familyName }: User) => `${givenName} ${familyName}`;

export const sortUsers = (a: User, b: User) => userFullname(a).localeCompare(userFullname(b));

export const User = createModelMetadata<User>({
  entityName: 'User',
  filterFn: findBySearch<User>({
    searchFields: ['email'],
    computedFields: [userFullname]
  }),
  sortComparer: (a, b) => sortUsers(a, b)
});
