import { Category as SharedCategory } from '@mri-platform/ag-shared/types';
import { createModelMetadata } from '@mri-platform/shared/core';

export type Category = SharedCategory;
export const Category = createModelMetadata<Category>({
  entityName: 'Category'
});

export interface CategorySort {
  categoryId: number;
  applications: number[];
}
