export const isAfterToday = (givenDate: Date): boolean => givenDate > beginningOfDay();

export const isTodayOrLater = (givenDate: Date): boolean => isAfterToday(givenDate) || isToday(givenDate);

export const isBeforeToday = (givenDate: Date): boolean => beginningOfDay() > givenDate;

export const isTodayOrEarlier = (givenDate: Date): boolean => isBeforeToday(givenDate) || isToday(givenDate);

export const today = (): Date => beginningOfDay();

//needs to use `getTime()` as JS Dates do not support `===`
export const isToday = (givenDate: Date): boolean => today().getTime() === givenDate.getTime();

export const beginningOfDay = (date: Date = new Date()): Date => setTimeForDate(date, 0, 0, 0, 0);

export const endOfDay = (date: Date = new Date()): Date => setTimeForDate(date, 23, 59, 59, 999);

export const setTimeForDate = (date: Date, h: number, m: number, s: number, ms: number): Date =>
  new Date(date.setHours(h, m, s, ms));
