import { createModelMetadata } from '@mri-platform/shared/core';
import { Dictionary } from '@ngrx/entity';
import { AdminApplication } from './admin-application';

export interface ApplicationUser {
  userId: string;
  applicationPkId: number;
  disabled: boolean;
}

export interface ApplicationUserPayload {
  changes: ApplicationUserChanges[];
  tag: string;
}

export interface ApplicationUserChanges {
  entityName: string;
  entities: ApplicationUser[];
  op: string;
}

export const ApplicationUser = createModelMetadata<ApplicationUser>({
  entityName: 'ApplicationUser',
  idFields: ['applicationPkId', 'userId']
});

export function isApplicationVisibleToUser(applicationUsers: Dictionary<ApplicationUser>) {
  return (application: AdminApplication) => {
    const appId = AdminApplication.selectIdAs<number>(application);
    const applicationUser = applicationUsers[appId];
    return applicationUser === undefined ? !application.offByAdmin : !applicationUser.disabled;
  };
}
