export enum EntityName {
  Application = 'Application',
  ApplicationUser = 'ApplicationUser',
  Client = 'Client',
  ClientFeature = 'ClientFeature',
  Message = 'Announcement',
  Partner = 'Partner',
  Portal = 'Portal',
  SelfHosted = 'SelfHosted',
  User = 'User',
  Category = 'Category',
  Product = 'Product'
}
