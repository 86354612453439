import { Injectable } from '@angular/core';
import { filter, from, interval, map, Observable, switchMap, tap } from 'rxjs';
import { ConfigurationApiService } from '../api/configuration-api.service';
import { HelloApiService } from '../api/home-api.service';
import { WebApplicationNames } from '../models';
import { AppUserAttributes } from '../models/app-user';
import { SharedServicesModule } from '../SharedServices.module';
import { ClientService } from './client.service';

@Injectable({
  providedIn: SharedServicesModule
})
export class PendoSharedService {
  constructor(
    private clientService: ClientService,
    private helloApiService: HelloApiService,
    private configApiService: ConfigurationApiService
  ) {}

  fetchScript(pendoApiKey: string): Observable<boolean> {
    return from(
      new Promise<boolean>(resolve => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const o = (window as any)['pendo'] || {};
        o._q = o._q || [];
        const pendoFunctions = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (let w = 0, x = pendoFunctions.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                // eslint-disable-next-line prefer-rest-params
                o._q[m === pendoFunctions[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(pendoFunctions[w]);
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://cdn.pendo.io/agent/static/${pendoApiKey}/pendo.js`;
        document.head.appendChild(script);
        script.onerror = async () => {
          // The script may have been blocked by an ad blocker
          console.error('The pendo script may have been blocked,');
          resolve(false);
        };
        script.onload = async () => {
          const sub = interval(100).subscribe(() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((window as any).pendo) {
              sub.unsubscribe();
              resolve(true);
            }
          });
        };
      })
    );
  }

  initPendo() {
    return this.configApiService.getConfiguration().pipe(
      map(cfg => cfg?.pendo?.apiKey),
      filter(Boolean),
      switchMap(pendoApiKey => this.fetchScript(pendoApiKey)),
      filter(loaded => !!loaded),
      switchMap(() => this.helloApiService.getHello()),
      tap(appUserAttrs => {
        const appName = this.clientService.getFromAdmin()
          ? WebApplicationNames.AgoraGatewayAdmin
          : WebApplicationNames.AgoraGatewayHome;
        this.initialize(appUserAttrs, appName);
      })
    );
  }

  initialize(attrs: AppUserAttributes, webAppName: WebApplicationNames) {
    const { userId, email, clientId, clientName, isGlobalAdmin } = attrs;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).pendo.initialize({
      visitor: {
        id: userId,
        email: email,
        isGlobalAdmin: isGlobalAdmin,
        webApp: webAppName
      },
      account: {
        id: clientId,
        name: clientName
      }
    });
  }

  updatePendoAgentClientId() {
    const selectedClient = this.clientService.getClientObject();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentMetadata = (window as any).pendo.getSerializedMetadata();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).pendo.updateOptions({
      ...currentMetadata,
      account: {
        id: selectedClient.id,
        name: selectedClient.clientName
      }
    });
  }
}
