import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SharedServicesModule } from '../SharedServices.module';
import { UserInfo } from '../models/app-user';

@Injectable({
  providedIn: SharedServicesModule
})
export class UserStateService {
  user = new BehaviorSubject<UserInfo | undefined>(undefined);
}
