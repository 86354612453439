import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { Client } from '../models/client-search';
import { ApiModule } from './api.module';
import { SharedApiService } from './shared-api.service';

@Injectable({
  providedIn: ApiModule
})
export class ClientApiService {
  constructor(private sharedApiService: SharedApiService) {}

  getClient(clientId: string): Observable<Client> {
    const url = `/api/v2/clients/${clientId}`;
    return from(this.sharedApiService.get(url, true));
  }

  getClients(): Observable<Client[]> {
    const url = `/api/v2/clients`;
    return from(this.sharedApiService.get(url, true));
  }
}
