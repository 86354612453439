import { CdnFile } from '@mri-platform/shared/common-types';
import { createModelMetadata, Nullable } from '@mri-platform/shared/core';
import { EntityName } from './entity-name';

export type NoCdnFile = Nullable<CdnFile>;

export const NO_CUSTOM_LOGO: NoCdnFile = {
  path: null,
  fileName: null,
  locationUrl: null
};

export const isCustomLogo = (logo: NoCdnFile | CdnFile): logo is CdnFile => logo.locationUrl != null;

export const mriLogoUri = './assets/Agora.svg';

export interface ClientSettings {
  id: string;
  logoFile: NoCdnFile | CdnFile;
}

export const ClientSettings = createModelMetadata<ClientSettings>({
  entityName: EntityName.Client
});
