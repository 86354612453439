import { createCollection, guid, metadataForKey, metadataForKeys } from 'angular-in-memory-web-api';
import { AdminApplication, ApplicationUser, setProductParent } from '..';
import { createExampleApplicationUsers } from './application-users';
import { createExampleApplications } from './applications';
import { partners } from './partners';
import { portals } from './portals';
import { selfHosted as selfhosted } from './self-hosted';

/*
IMPORTANT: keep the number of example instances in each collection/list small(ish)
This is to make snapshot tests easier to review
*/

const examples: Partial<AdminApplication>[] = [
  /** Official Applications */
  {
    applicationName: 'Okta Alloy X.6 FF',
    applicationDescription: undefined,
    url: 'https://mrix6-trunk-okta.ff.mrisoftware.net/MRIWeb/',
    iconSource: 'https://mripdcdnintff01.azureedge.net/mri/MRI-ApplicationGateway-Icons-Analytix.svg',
    productId: 10,
    offByAdmin: true
  },
  {
    applicationName: 'Okta Alloy X.5 FF',
    applicationDescription: undefined,
    url: 'https://mrix5-release.ff.mrisoftware.net/MRIWeb/',
    iconBackgroundColor: '#ff0000',
    iconSource: 'https://mripdcdnintff01.azureedge.net/mri/MRI-ApplicationGateway-Icons-Callmax.svg',
    productId: 11
  },
  {
    applicationName: 'Alloy Release X5 QA SaaS',
    applicationDescription: undefined,
    url: 'https://mrix5-release.qasaas.mrisoftware.net',
    iconBackgroundColor: '#42ec11',
    iconSource: 'https://mripdcdnintff01.azureedge.net/mri/MRI-ApplicationGateway-Icons-Analytix.svg',
    productId: 12
  },
  /** Client Application with image icon, since productId is undefined */
  {
    applicationName: 'Notifications Dummy',
    applicationDescription: 'Test Notifications Here',
    url: 'https://dev-mri-platform-dummy-int.redmz.mrisoftware.com/',
    iconSource: 'https://mripdcdnintff01.blob.core.windows.net/mri/MRI-ApplicationGateway-Icons-microservices.jpeg'
  },
  /** Client Application with initials */
  {
    applicationName: 'Analytix Dev',
    applicationDescription: undefined,
    url: 'https://analytix01.ff.mrisoftware.net/#/login',
    iconBackgroundColor: '#ff0000',
    iconSource: undefined,
    iconInitials: 'ATZ'
  },
  /** Partner Application without defaultUrl*/
  setProductParent(
    {
      applicationDescription: 'Partner with No URL',
      url: 'https://partner-1.example-partner-application',
      applicationOwnerType: 'Client'
    } as AdminApplication,
    partners[0]
  ),
  /** Partner Application with defaultUrl*/
  setProductParent(
    {
      applicationDescription: 'Partner with URL',
      applicationOwnerType: 'Client'
    } as AdminApplication,
    partners[1]
  ),
  /**Resident Connect as a sample portal application */
  setProductParent(
    {
      applicationDescription: 'Test Resident Connect',
      url: 'https://example-portal-rc-1',
      applicationOwnerType: 'Client'
    } as AdminApplication,
    portals[0]
  )
];

const examplesApplicationUsers: Partial<ApplicationUser>[] = [
  {
    applicationPkId: 1,
    userId: '2',
    disabled: true
  },
  {
    applicationPkId: 2,
    userId: '3'
  }
];

export const testDbApp = {
  applications: createCollection(createExampleApplications(examples), metadataForKey(AdminApplication.idField)),
  // note: angular-in-memory-web-api is forcing us to use all lower case for this collection name
  applicationusers: createCollection(createExampleApplicationUsers(examplesApplicationUsers), collection => {
    const metadata = metadataForKeys(ApplicationUser.idFields)(collection);
    return {
      ...metadata,
      // this is a hack to workaround applicationusers being saved as a batch
      genId: () => ({ id: guid() })
    };
  }),
  // also note: real url for portals is currently /products/portals,
  // but the in mem api also hates nested resources.
  // see https://github.com/angular/in-memory-web-api/issues/77 for reference.
  // this is handled in fake-db-impl.service with an interceptor.
  portals,
  partners,
  selfhosted
};
