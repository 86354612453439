import { EntityIdType } from '@mri-platform/shared/entity';
import { Dictionary } from '@ngrx/entity';
import { AdminApplication } from './admin-application';
import { ApplicationUser, isApplicationVisibleToUser } from './application-user';
import { User } from './user';

export interface UserApplicationsVisibility
  extends Pick<User, 'givenName' | 'familyName' | 'email'>,
    Pick<ApplicationUser, 'userId'> {
  [applicationKey: number]: boolean;
}

const appFieldNamePrefix = 'application_';
export const toApplicationFieldName = (id: EntityIdType) => `${appFieldNamePrefix}${id}`;
export const parseApplicationIdFromFieldName = (value: string) => +value.split(appFieldNamePrefix)[1];
export const isApplicationFieldName = (value: string) => value.startsWith(appFieldNamePrefix);

export function createUserApplicationsVisibility(
  user: User,
  applications: AdminApplication[],
  applicationUsers: Dictionary<ApplicationUser>
): UserApplicationsVisibility {
  const { givenName, familyName, email, id: userId } = user;

  const initialResult = { givenName, familyName, email, userId };
  const isVisible = isApplicationVisibleToUser(applicationUsers);

  const result = applications.reduce(
    (acc, a) => ({
      ...acc,
      [toApplicationFieldName(AdminApplication.selectId(a))]: isVisible(a)
    }),
    initialResult
  );
  return result;
}
