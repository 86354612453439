import { KeyValueDifferFactory } from '@angular/core';

// note: subtractObject is NOT in the lodash library but it should be!
export const subtractObject = <T extends object>(
  minuend: T,
  subtrahend: T,
  differFactory: KeyValueDifferFactory
): Partial<T> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const differ = differFactory.create<unknown, any>();
  differ.diff(subtrahend); // set initial value for subsequent comparisons
  const changes = differ.diff(minuend);

  if (changes) {
    const diff: { [key: string]: unknown } = {};
    changes.forEachChangedItem(r => {
      diff[r.key] = r.currentValue;
    });
    changes.forEachAddedItem(r => {
      diff[r.key] = r.currentValue;
    });
    return diff as Partial<T>;
  } else {
    return {};
  }
};
