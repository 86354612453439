import { User } from '..';
import { createExampleUsers } from './user';

export const examples: Partial<User>[] = [
  {
    givenName: 'Abe',
    familyName: 'Able',
    isAdmin: true,
    isAppGatewayEnabled: true
  },
  {
    givenName: 'Bob',
    familyName: 'Baker',
    email: 'bbaker@bakers.com'
  },
  {
    givenName: 'Charly',
    familyName: 'Charles'
  },
  {
    givenName: 'David',
    familyName: 'Davis'
  }
];

export const testDbUser = {
  users: createExampleUsers([...examples])
};
