import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ClientApiService } from '../api/client-api.service';
import { Client } from '../models/client-search';
import { SharedServicesModule } from '../SharedServices.module';
import { ClientStateService } from '../state/client-state.service';

@Injectable({
  providedIn: SharedServicesModule
})
export class ClientService {
  constructor(
    private ClientApiService: ClientApiService,
    private clientStateService: ClientStateService
  ) {}

  setFromAdmin(value: boolean): void {
    this.clientStateService.fromAdmin = value;
  }

  getFromAdmin(): boolean {
    return this.clientStateService.fromAdmin;
  }

  getClient(clientId: string): Observable<Client> {
    return this.ClientApiService.getClient(clientId);
  }

  getClients(): Observable<Client[]> {
    return this.ClientApiService.getClients();
  }

  getClientId(): Observable<string | undefined> {
    return this.clientStateService.$clientId.asObservable();
  }

  setClientId(clientId: string): void {
    //clientId = clientId.toUpperCase();
    this.clientStateService.clientId = clientId;
    this.clientStateService.$clientId.next(clientId);
  }

  setClientIdSet(): void {
    this.clientStateService.clientIdSet = true;
  }

  getClientIdSet(): boolean {
    return this.clientStateService.clientIdSet;
  }

  getClientIdValue(): string {
    return this.clientStateService.clientId;
  }

  getClientListLoaded(): Observable<boolean> {
    return this.clientStateService.clientListLoaded;
  }

  setClientListLoaded(loaded: boolean): void {
    this.clientStateService.clientListLoaded.next(loaded);
  }

  getClientObject() {
    return this.clientStateService.client;
  }

  setClientObject(client: Client): void {
    this.clientStateService.client = client;
  }

  getClientLogo() {
    if (
      this.clientStateService.client &&
      this.clientStateService.client.logoFile &&
      this.clientStateService.client.logoFile.locationUrl
    ) {
      return this.clientStateService.client.logoFile.locationUrl;
    } else {
      return '';
    }
  }
}
