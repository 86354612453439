import { Product } from '..';
import { portals } from './portals';

const exampleWithoutUrl: Pick<Product, 'iconBackgroundColor' | 'iconSource' | 'groupingOn'> = {
  iconBackgroundColor: '#77b288',
  iconSource: 'https://mripdcdnintff01.azureedge.net/mri/MRI-ApplicationGateway-Icons-Productosaurus.svg',
  groupingOn: false
};

const exampleWithUrl: Pick<Product, 'defaultUrl' | 'iconBackgroundColor' | 'iconSource' | 'groupingOn'> = {
  ...exampleWithoutUrl,
  defaultUrl: 'https://partner.application'
};

const nextId = portals.length + 1;

/**
 * Even-numbered partners will be generated with defaultUrls.
 */
export const partners: Product[] = [...Array(4).keys()].map(i => {
  const productNumber = i + 1;
  const id = productNumber + nextId;
  const baseExample = productNumber % 2 === 0 ? exampleWithUrl : exampleWithoutUrl;
  return { ...baseExample, id, name: `Partner ${productNumber}`, isPartner: true };
});
