import { Injectable } from '@angular/core';
import { Product } from '@mri-platform/ag-shared/types';
import { Observable, from } from 'rxjs';
import { ClientService } from '../services/client.service';
import { ApiModule } from './api.module';
import { SharedApiService } from './shared-api.service';

@Injectable({
  providedIn: ApiModule
})
export class ProductApiService {
  constructor(
    private sharedApiService: SharedApiService,
    private clientService: ClientService
  ) {}

  getProducts(): Observable<Product[]> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/products`;
    return from(this.sharedApiService.get(url, true));
  }

  getSelfHostedProduct(): Observable<Product[]> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/products/selfhosted`;
    return from(this.sharedApiService.get(url, true));
  }

  getPortalsProduct(): Observable<Product[]> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/products/portals/`;
    return from(this.sharedApiService.get(url, true));
  }

  getPartnerProducts(): Observable<Product[]> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/products/partners`;
    return from(this.sharedApiService.get(url, true));
  }
}
