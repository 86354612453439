import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SharedServicesModule } from '../SharedServices.module';
import { Client } from '../models/client-search';

@Injectable({
  providedIn: SharedServicesModule
})
export class ClientStateService {
  $clientId = new BehaviorSubject<string | undefined>(undefined);
  clientId = '';
  clientIdSet = false;
  clientListLoaded = new Subject<boolean>();
  client!: Client;
  fromAdmin = false;
  constructor() {
    this.$clientId.next(undefined);
  }
}
