import type { ParamMap } from '@angular/router';
import {
  AdminApplication as SharedAdminApplication,
  Application as SharedApplication,
  ApplicationOwnerType as SharedOwnerType
} from '@mri-platform/ag-shared/types';
import { AvatarIcon, avatarDefaultCaptionBgColor } from '@mri-platform/shared/common-types';
import { createModelMetadata, findBySearch } from '@mri-platform/shared/core';
import type { EntityList } from '@mri-platform/shared/entity';
import { Product } from './product';

export type ApplicationOwnerType = Exclude<SharedOwnerType, 'User'>;
type ApplicationDetails = SharedAdminApplication;

//TODO: Update to use ag-shared type post-WFE migration
export type AdminApplication = ApplicationDetails & AvatarIcon & { applicationOwnerType: ApplicationOwnerType };

export function sortAdminApplication(a: AdminApplication, b: AdminApplication): number {
  return (
    (a.applicationName ?? '').localeCompare(b.applicationName ?? '') ||
    (a.applicationDescription ?? '').localeCompare(b.applicationDescription ?? '')
  );
}

export const AdminApplication = createModelMetadata<AdminApplication>({
  entityName: 'Application',
  idField: 'applicationPkId',
  filterFn: findBySearch<AdminApplication>({ searchFields: ['applicationName', 'applicationDescription'] }),
  sortComparer: sortAdminApplication
});

export function createApplication(): AdminApplication {
  return {
    applicationOwnerType: 'Client',
    applicationPkId: -1,
    iconBackgroundColor: avatarDefaultCaptionBgColor,
    applicationName: '',
    url: '',
    offByAdmin: false,
    productId: undefined
  };
}

export const isClientAdminDefined = ({ applicationOwnerType }: AdminApplication) => applicationOwnerType === 'Client';

export const canDeleteApplication = isClientAdminDefined;
export const canDeleteIconFileFrom = (app: AdminApplication) => app.productId == null;
export const canEditApplication = isClientAdminDefined;

export type ClientApplicationType = 'ClientDefined' | 'Portal' | 'PreferredPartner' | 'SelfHosted';

export const getClientApplicationType = ({
  entity,
  partners,
  portals,
  selfHosted
}: {
  entity: AdminApplication;
  partners: EntityList<Product>;
  portals: EntityList<Product>;
  selfHosted: EntityList<Product>;
}): ClientApplicationType | null => {
  if (!isClientAdminDefined(entity)) {
    return null;
  } else if (entity.productId && partners.map[entity.productId]) {
    return 'PreferredPartner';
  } else if (entity.productId && portals.map[entity.productId]) {
    return 'Portal';
  } else if (entity.productId && selfHosted.map[entity.productId]) {
    return 'SelfHosted';
  }
  if (entity.productId) {
    // unimplemented state - supported so far are client defined app that have an associated portal or partner product
    // this is safe fallback behavior as it will result in a non-editable form
    return null;
  } else {
    return 'ClientDefined';
  }
};

export interface ClientApplicationTypeDetail {
  appType: ClientApplicationType;
  readableDescriptor: string;
}

export const ClientApplicationTypeDetails: Record<ClientApplicationType, ClientApplicationTypeDetail> = {
  ClientDefined: {
    appType: 'ClientDefined',
    readableDescriptor: 'client-defined'
  },
  Portal: {
    appType: 'Portal',
    readableDescriptor: 'portal'
  },
  SelfHosted: {
    appType: 'SelfHosted',
    readableDescriptor: 'self-hosted'
  },
  PreferredPartner: {
    appType: 'PreferredPartner',
    readableDescriptor: 'Partner'
  }
} as const;

export const isValidClientAppType = (clientAppType: string | null): clientAppType is ClientApplicationType =>
  clientAppType ? clientAppType in ClientApplicationTypeDetails : false;

export interface NewApplicationOptions {
  clientAppType: ClientApplicationType;
  productId: number | null;
}

export function parseNewAppQueryStringParams(queryParams: ParamMap): NewApplicationOptions | null {
  if (!queryParams.has('clientAppType')) {
    return null;
  }

  const parseClientAppString = (value: string | null) => (isValidClientAppType(value) ? value : 'ClientDefined');

  return {
    clientAppType: parseClientAppString(queryParams.get('clientAppType')),
    productId: queryParams.has('productId') ? +(queryParams.get('productId') ?? '') : null
  };
}

export function setProductParent(
  application: AdminApplication,
  { name: applicationName, defaultUrl, iconBackgroundColor, iconSource, id: productId }: Product
): AdminApplication {
  return {
    ...application,
    applicationName,
    iconSource,
    iconBackgroundColor,
    productId,
    url: defaultUrl ?? application.url
  };
}

export function toSharedApplication(app: AdminApplication): SharedApplication {
  const {
    applicationPkId: id,
    applicationName: name,
    applicationDescription: description,
    applicationOwnerType: ownerType,
    productId,
    url,
    iconBackgroundColor,
    iconSource,
    iconInitials,
    iconPath,
    iconFileName,
    offByAdmin
  } = app;

  return {
    id,
    name,
    description,
    ownerType,
    productId,
    url,
    iconBackgroundColor,
    iconSource,
    iconInitials,
    iconPath,
    iconFileName,
    offByAdmin,
    off: false,
    sortOrder: 0
  };
}
