import { Injectable } from '@angular/core';
import { Observable, concatMap, from, map, toArray } from 'rxjs';
import { ApplicationUser, ApplicationUserChanges, ApplicationUserPayload, ClientSettings } from '../models';
import { AdminApplication } from '../models/admin-application';
import { ClientService } from '../services/client.service';
import { ApiModule } from './api.module';
import { SharedApiService } from './shared-api.service';

@Injectable({
  providedIn: ApiModule
})
export class AdminApplicationApiService {
  constructor(
    private sharedApiService: SharedApiService,
    private clientService: ClientService
  ) {}

  getApplications(): Observable<AdminApplication[]> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/applications`;
    return from(this.sharedApiService.get(url, true));
  }

  getApplicationUsers(): Observable<ApplicationUser[]> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/applicationusers`;
    return from(this.sharedApiService.get(url, true));
  }

  chunkArray<T>(arr: T[], chunkSize: number): T[][] {
    const result: T[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  updateApplicationUsers(users: ApplicationUser[]): Observable<number> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/applicationusers`;

    const chunks = this.chunkArray(users, 50);

    return from(chunks).pipe(
      concatMap(chunk => {
        const applicationUserChanges: ApplicationUserChanges[] = [
          {
            entityName: 'ApplicationUser',
            entities: chunk,
            op: 'Upsert'
          }
        ];

        const payload: ApplicationUserPayload = {
          changes: applicationUserChanges,
          tag: 'Save ApplicationUser(s) data'
        };

        return from(this.sharedApiService.postNoReturn(url, payload));
      }),
      toArray(),
      map(() => chunks.length)
    );
  }

  deleteAdminApplication(id: string) {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/applications/${id}`;
    from(this.sharedApiService.deleteNoReturn(url));
  }

  postAdminApplications(application: AdminApplication): Observable<AdminApplication> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/applications`;
    return from(this.sharedApiService.post(url, application));
  }

  putAdminApplications(application: AdminApplication): Observable<AdminApplication> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/applications/${application.applicationPkId}`;
    return from(this.sharedApiService.put(url, application));
  }

  getClientSettings(): Observable<ClientSettings[]> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/clients/`;
    return from(this.sharedApiService.get(url, true));
  }
}
