import { Product } from '..';
import { partners } from './partners';

const baseExample: Pick<Product, 'iconBackgroundColor' | 'groupingOn' | 'isPartner'> = {
  iconBackgroundColor: '#00b288',
  groupingOn: true,
  isPartner: false
};

const nextId = partners.length + 1;

/**
 * These are the only two portals we support at the moment.
 * The Welcome API behind the scenes is looking for products with these specific names.
 */
export const selfHosted: Product[] = [
  {
    ...baseExample,
    id: nextId,
    name: 'Self-Hosted PMX',
    iconSource: 'https://mripdcdnintff01.blob.core.windows.net/mri/MRI-ApplicationGateway-Icons-Analytix.svg'
  },
  {
    ...baseExample,
    id: nextId + 1,
    name: 'Self-Hosted RG',
    iconSource: 'https://mripdcdnintff01.blob.core.windows.net/mri/MRI-ApplicationGateway-Icons-ReportGateway.svg'
  }
];
