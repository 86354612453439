import { Category, ProductLikeApp, Application as SharedApplication } from '@mri-platform/ag-shared/types';
import { avatarDefaultCaptionBgColor } from '@mri-platform/shared/common-types';
import { createModelMetadata, findBySearch } from '@mri-platform/shared/core';
import { EntityList, NEW_ENTITY_ID } from '@mri-platform/shared/entity';
import { ClientApplicationType } from './admin-application';
import { EntityName } from './entity-name';
import { Product } from './product';

export type Application = SharedApplication;
export const Application = createModelMetadata<Application>({
  entityName: EntityName.Application,
  filterFn: findBySearch<Application>({ searchFields: ['name', 'description'] }),
  sortComparer: sortApplication
});

export function sortApplication(a: Application, b: Application): number {
  return (a.name ?? '').localeCompare(b.name ?? '') || (a.description ?? '').localeCompare(b.description ?? '');
}

export function createUserTypeApplication(): Application {
  return {
    id: NEW_ENTITY_ID,
    name: '',
    iconBackgroundColor: avatarDefaultCaptionBgColor,
    url: '',
    oAuthId: undefined,
    off: false,
    offByAdmin: false,
    ownerType: 'User',
    productId: undefined,
    categoryId: 1,
    sortOrder: -9999
  };
}

export const toCategory = (value: CategoryWithApplications): Category => {
  const { applications: _applications, ...category } = value;
  return category;
};

export interface CategoryWithApplications extends Category {
  applications: (Application | ProductLikeApp)[];
}

export interface ApplicationInfo {
  applicationName: string;
  oAuthId: string;
  checked: boolean;
  iconUrl: string;
}

export interface AdminUserWithStatus {
  userId: string;
  disabledStatus: boolean;
}
export interface ComponentState extends FormState<Application> {
  isIconSelectorOpen: boolean;
  isNew: boolean;
  clientAppType: ClientApplicationType | null;
  portals: EntityList<Product>;
  selfHosted: EntityList<Product>;
}

export interface FormState<T> {
  isDirty: boolean;
  isValid: boolean;
  model: T;
}

export interface buttonOptions {
  text: string;
  click: () => void;
}

export enum buttonOptionsType {
  clientDefined = 'ClientDefined',
  portal = 'Portal',
  selfHosted = 'SelfHosted',
  partner = 'Partner'
}
