import { Injectable } from '@angular/core';
import { UserApiService } from '../api/user-api.service';
import { UserInfo } from '../models/app-user';
import { SharedServicesModule } from '../SharedServices.module';
import { UserStateService } from '../state/user-state.service';

@Injectable({
  providedIn: SharedServicesModule
})
export class UserService {
  constructor(
    private userStateService: UserStateService,
    private userApiService: UserApiService
  ) {}

  setUser(user: UserInfo) {
    this.userStateService.user.next(user);
  }

  getUserFromState() {
    return this.userStateService.user;
  }

  updateDefaultClientId(userId: string, clientId: string | null) {
    return this.userApiService.updateDefaultClientId(userId, clientId);
  }

  getUserInfo() {
    return this.userApiService.getUserInfo();
  }

  updateLoggedInUserCache(clientId: string) {
    return this.userApiService.updateLoggedInUserCache(clientId);
  }
}
