/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { ClientService, SharedApiService, UserInfo } from '@mri-platform/ag-shared/services';
import { Observable, from } from 'rxjs';
import { DefaultClientIdPayLoad, User, UserChanges, UserPayload } from '../models/user';
import { ApiModule } from './api.module';

@Injectable({
  providedIn: ApiModule
})
export class UserApiService {
  constructor(
    private sharedApiService: SharedApiService,
    private clientService: ClientService
  ) {}

  getUsers(): Observable<User[]> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/users`;
    return from(this.sharedApiService.get(url, true));
  }

  updateUser(user: User): Observable<number> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/users/${user.id}`;
    return from(this.sharedApiService.putNoReturn(url, user));
  }

  updateUsers(users: User[]): Observable<number> {
    const userChanges: UserChanges[] = [
      {
        entityName: 'User',
        entities: users,
        op: 'Upsert'
      }
    ];

    const payload: UserPayload = {
      changes: userChanges,
      tag: 'Save User(s) data'
    };
    const url = `/api/v2/${this.clientService.getClientIdValue()}/admin/users`;
    return from(this.sharedApiService.postNoReturn(url, payload));
  }

  updateLoggedInUserCache(clientId: string) {
    const url = `/api/v1/client/${clientId}/user`;
    return from(this.sharedApiService.patchNoReturn(url));
  }

  getUserInfo(): Observable<UserInfo> {
    const url = `/api/v2/user`;
    return from(this.sharedApiService.get(url, true));
  }

  updateDefaultClientId(userId: string, clientId: string | null) {
    const url = `/api/v2/user/${userId}/defaultclientid`;
    const payload: DefaultClientIdPayLoad = {
      defaultClientId: clientId
    };
    return from(this.sharedApiService.postNoReturn(url, payload));
  }
}
