import { Product } from '..';

const baseExample: Pick<Product, 'iconBackgroundColor' | 'groupingOn' | 'isPartner'> = {
  iconBackgroundColor: '#00b288',
  groupingOn: true,
  isPartner: false
};

/**
 * These are the only two portals we support at the moment.
 * The Welcome API behind the scenes is looking for products with these specific names.
 */
export const portals: Product[] = [
  {
    ...baseExample,
    id: 1,
    name: 'Resident Connect',
    iconSource:
      'https://mripdcdnintqasaas01.blob.core.windows.net/mri/MRI-ApplicationGateway-Icons-resident-connect-icon.svg'
  },
  {
    ...baseExample,
    id: 2,
    name: 'Tenant Connect',
    iconSource:
      'https://mripdcdnintqasaas01.blob.core.windows.net/mri/MRI-ApplicationGateway-Icons-tenant-connect-icon.svg'
  }
];
