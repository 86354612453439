import { AdminApplication } from '..';

const baseExample: AdminApplication = {
  applicationPkId: 1,
  applicationName: 'Sample app name Sample app name Long Name is this the longest name ever it is lol lol ol',
  applicationDescription: 'Sample app description',
  applicationOwnerType: 'Official',
  url: 'https://does-not-exist.mrisoftware.net',
  iconBackgroundColor: '#044d66',
  iconSource: 'https://mripdcdnintff01.azureedge.net/mri/MRI-ApplicationGateway-Icons-Analytix.svg',
  iconInitials: undefined,
  offByAdmin: false,
  productId: undefined
};

export const createExampleApplications = (list: Partial<AdminApplication>[]): AdminApplication[] =>
  list.map((data, i) => ({ ...baseExample, ...data, applicationPkId: i }));
