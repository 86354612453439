import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { ApiFeatureFlag } from '../models';
import { ClientService } from '../services/client.service';
import { ApiModule } from './api.module';
import { SharedApiService } from './shared-api.service';

@Injectable({
  providedIn: ApiModule
})
export class FeaturesApiService {
  constructor(
    private sharedApiService: SharedApiService,
    private clientService: ClientService
  ) {}

  getFeatures(): Observable<string[]> {
    const url = `/api/v2/${this.clientService.getClientIdValue()}/features`;
    return from(this.sharedApiService.get(url, true));
  }

  getFeatureFlags(): Observable<ApiFeatureFlag[]> {
    const url = `/api/v1/admin/featureflags`;
    return from(this.sharedApiService.get(url, true));
  }
}
